import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ExecutionProjectIssueQueryData {
  data: any;
  uniqueTags: string[];
  uniqueAssignees: string[];
}
const initialState: ExecutionProjectIssueQueryData = {
  data: [],
  uniqueTags: [],
  uniqueAssignees: [],
};
const executionProjectIssueQuerySlice = createSlice({
  name: "executionProjectIssueQuery",
  initialState,
  reducers: {
    setExecutionProjectIssueQueryData: (
      state,
      action: PayloadAction<{
        data: any;
        uniqueTags: string[];
        uniqueAssignees: string[];
      } | null>
    ) => {
      return action.payload ?? initialState;
    },
  },
});

export const { setExecutionProjectIssueQueryData } =
  executionProjectIssueQuerySlice.actions;

export default executionProjectIssueQuerySlice.reducer;
