export const TenantDetailsTabs = {
  Customer: "Customer",
  CustomerSegment: "", //'Customer Segment',
  NPS: "NPS",
  TenantNPS: "TENANT NPS",
  CSAT: "CSAT",
  CHI: "CHI",
  Survey: "SURVEY",
  Insights: "Insights Data",
  InsightsDetractor: "Detractor Common Words",
  NetBenefits: "Net Benefits",
  CustomMetrics: "Custom Metrics",
  CES: "CES",
  CustomerVisits: "Customer Visits",
  AverageCSAT: "Average CSAT",
  customerEffort: "Customer Effort",
  taskCompletionRate: "Task Completion Rate",
  likelihoodToReturn: "Likelihood To Return",
  ContentCSAT: "Content CSAT",
  InterceptResponseRate: "Intercept Response Rate",
  ContentEffectiveness: "Content Effectiveness",
  project: "Project",
  projectIssue: "Project Issue",
  projectSummary: "Project Summary",
};

const Tabs = TenantDetailsTabs;

export interface TabConfig {
  tabName: string;
  subTabs: string[] | null;
  default?: string;
}
export const ClientTabs: TabConfig[] = [
  {
    tabName: "Customer Management",
    subTabs: [Tabs.Customer, Tabs.CHI],
    default: Tabs.Customer,
  },
  {
    tabName: "Relational",
    subTabs: [Tabs.NPS, Tabs.TenantNPS, Tabs.Survey],
    default: Tabs.NPS,
  },
  {
    tabName: "Transactional",
    subTabs: [Tabs.CSAT, Tabs.CES],
    default: Tabs.CSAT,
  },
  {
    tabName: "Digital",
    subTabs: [
      Tabs.CustomerVisits,
      Tabs.AverageCSAT,
      Tabs.customerEffort,
      Tabs.taskCompletionRate,
      Tabs.likelihoodToReturn,
    ],
    default: Tabs.CustomerVisits,
  },
  {
    tabName: "Content",
    subTabs: [
      Tabs.ContentCSAT,
      Tabs.InterceptResponseRate,
      Tabs.ContentEffectiveness,
    ],
    default: Tabs.ContentCSAT,
  },
  {
    tabName: "Insights",
    subTabs: [Tabs.Insights, Tabs.InsightsDetractor],
    default: Tabs.Insights,
  },
  {
    tabName: "Execution",
    subTabs: [Tabs.project, Tabs.projectIssue, Tabs.projectSummary],
    default: Tabs.project,
  },
  {
    tabName: "Net Benefit",
    subTabs: [Tabs.NetBenefits, Tabs.CustomMetrics],
    default: Tabs.NetBenefits,
  },
  {
    tabName: "User Management",
    subTabs: null,
  },
];

export const CustomerGridColumns = {
  id: "ID",
  priority: "PRIORITY",
  name: "NAME",
  address: "ADDRESS",
  city: "CITY",
  stateProvince: "STATE-PROVINCE",
  postalCode: "POSTAL CODE",
  industry: "INDUSTRY",
  phone: "PHONE",
  url: "URL",
};

export const NpsGridColumns = {
  id: "ID",
  customer: "CUSTOMER NAME",
  date: "DATE",
  score: "SCORE",
};

export const CsatGridColumns = {
  id: "ID",
  customer: "CUSTOMER NAME",
  date: "DATE",
  score: "SCORE",
  touchpoint: "TOUCHPOINT",
};

export const CHIGridColumns = {
  id: "ID",
  customer: "CUSTOMER NAME",
  score: "HEALTH SCORE",
  date: "DATE",
};

export const InsightsGridColumns = {
  id: "INSIGHT ID",
  title: "TITLE",
  description: "INSIGHT DESCRIPTION",
  frictionDescription: "FRICTION DESCRIPTION",
  date: "DATE",
  status: "STATUS",
  promoted: "PROMOTED",
  newlyDiscovered: "NEWLY DISCOVERED",
  customersImpacted: "CUSTOMERS IMPACTED",
  importance: "IMPORTANCE",
  severity: "SEVERITY",
  frequency: "FREQUENCY",
  customerCentricScore: "CUSTOMER CENTRIC TOTAL",
  businessValue: "BUSINESS VALUE",
  implementationEase: "EASE OF IMPLEMENTATION",
  strategicAlignment: "STRATEGIC ALIGNMENT",
  orgCentricScore: "ORG CENTRIC TOTAL",
  overallScore: "OVERALL TOTAL",
  insightImplementationTimeFrame: "TIME FRAME",
  detractorTopics: "DETRACTOR TOPICS",
};

export const InsightsDetractorGridColumns = {
  id: "INSIGHT ID",
  name: "COMMON WORD",
  frequency: "FREQUENCY",
};

export const NetBenefitsGridColumns = {
  id: "id",
  project: "PROJECT NAME",
  valueCapturedAmount: "VALUE CAPTURED",
  timeline: "TIMELINE",
  roi: "ROI",
};

export const CustomMetricGridColumns = {
  id: "ID",
  timeline: "TIMELINE",
  value: "VALUE OF METRIC",
};

export const insightTimeFrame: any = {
  ZERO_TO_THREE_MONTHS: "0 - 3 Months",
  FOUR_TO_SIX_MONTHS: "4 - 6 Months",
  SEVEN_TO_TWELVE_MONTHS: "7 - 12 Months",
  GREATER_THAN_TWELVE_MONTHS: "12+ Months",
};

export const SurveyGridColumns = {
  id: "SURVEY ID",
  title: "SURVEY NAME",
  type: "SURVEY TYPE",
  startDate: "SURVEY START DATE",
  endDate: "SURVEY END DATE",
  customer: "CUSTOMER",
  customerSegment: "CUSTOMER SEGMENT",
  numSent: "NUM SENT",
  responsesReceived: "TOTAL RESPONSE RECEIVED",
  status: "STATUS",
};

export const sruveyStatus: any = [
  "PLANNED",
  "STARTED",
  "COMPLETED",
  "CANCELLED",
];
export enum ProjectStatusEnum {
  PENDING = "PENDING",
  DELAYED = "DELAYED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}
export enum ProjectApprovalStatusEnum {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}
export enum ProjectIssueStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
}
export enum ProjectIssueTypeEnum {
  KEY_RISKS = "KEY_RISKS",
  KEY_ISSUES = "KEY_ISSUES",
}
export enum ProjectIssuePriorityEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}
export const projectStatusValues = Object.values(ProjectStatusEnum);
export const ProjectIssueTypeValues = Object.values(ProjectIssueTypeEnum);
export const ProjectIssueStatusValues = Object.values(ProjectIssueStatusEnum);
export const projectApprovalStatusValues = Object.values(
  ProjectApprovalStatusEnum
);
export const ProjectIssuePriorityValues = Object.values(
  ProjectIssuePriorityEnum
);
export const TenantProjectIssueTypeEnum = {
  [ProjectIssueTypeEnum.KEY_RISKS]: "Key Risks",
  [ProjectIssueTypeEnum.KEY_ISSUES]: "Key Issues",
};
export const CustomerEfforScoreGridColumns = {
  id: "ID",
  value: "SCORE",
  date: "DATE",
};

export const CustomerVisitsGridColumns = {
  id: "ID",
  value: "CUSTOMER VISITS",
  date: "DATE",
};

export const AverageCsatGridColumns = {
  id: "ID",
  value: "VALUE",
  date: "DATE",
};

export const SignalCommonMetrics = {
  CUSTOMER_EFFORT_SCORE: "CUSTOMER_EFFORT_SCORE",
  CUSTOMER_VISITS: "CUSTOMER_VISITS",
  AVERAGE_DIGITAL_CSAT: "AVERAGE_DIGITAL_CSAT",
  DIGITAL_CUSTOMER_EFFORT: "DIGITAL_CUSTOMER_EFFORT",
  TASK_COMPLETION_RATE: "TASK_COMPLETION_RATE",
  LIKELIHOOD_TO_RETURN: "LIKELIHOOD_TO_RETURN",
  INTERCEPT_RESPONSE_RATE: "INTERCEPT_RESPONSE_RATE",
  CONTENT_CSAT: "CONTENT_CSAT",
};

export const ContentEffectivenessGridColumns = {
  id: "ID",
  content: "CONTENT",
  score: "SCORE",
  date: "DATE",
  mostCommonlySearched: "MOST COMMONLY SEARCHED",
  needsAttention: "NEEDS ATTENTION",
  comments: "COMMENTS",
};

export const ExecutionProjectGridColumns = {
  id: "PROJECT ID",
  insights: "INSIGHTS",
  projectName: "PROJECT NAME",
  projectDescription: "PROJECT DESCRIPTION",
  startDate: "START DATE",
  endDate: "END DATE",
  status: "STATUS",
  pctComplete: "PCT COMPLETE",
  approvalStatus: "APPROVAL STATUS",
};

export const ExecutionProjectIssueGridColumns = {
  id: "ISSUE ID",
  project: "PROJECT NAME",
  issueName: "ISSUE NAME",
  issueDescription: "ISSUE DESCRIPTION",
  issueStatus: "ISSUE STATUS",
  issueType: "ISSUE TYPE",
  issuePriority: "ISSUE PRIORITY",
  tags: "TAGS",
  assignees: "ASSIGNEE(S)",
  dueDate: "DUE DATE",
};

export const ExecutionProjectSummaryGridColumns = {
  id: "ID",
  projectId: "PROJECT ID",
  project: "PROJECT NAME",
  ownerName: "OWNER",
  driver: "DRIVER",
  approvers: "APPROVER",
  contributors: "CONTRIBUTORS",
  informed: "INFORMED",
  objective: "OBJECTIVE",
  dueDate: "DUE DATE",
};
