import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PlusIcon from "../../../../assets/images/PlusIcon.svg";
import CrossIcon from "../../../../assets/images/cross.png";
import FilledDropDown from "../../../../assets/images/FilledDropDown.svg";
import { Button, InputGroup, Form } from "react-bootstrap";
import {
  checkItemExistence,
  filterItemsBySearchString,
} from "../../utils/ExecutionProjectIssueHelper";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../../../store";
import { literals } from "../../../../theme/literals";
import Text from "../../../../component/Text/Text";
import { colors } from "../../../../theme/colors";
import "../../../../styles/page/ExecutionProjectIssues/EPIAssigneeCell.scss";
import "../../../../sass/typography.scss";

const renderAssigneeItem = (
  item: string,
  index: number,
  handleAssigneeRemove: any
) => (
  <div key={`assignee_${index}`} className="epi-assignee-content-wrapper">
    <span className={`caption2 ${item?.length > 15 ? "break-item" : ""}`}>
      {item}
    </span>
    <img
      className="cross-icon"
      src={CrossIcon}
      alt="cross-icon"
      onClick={() => handleAssigneeRemove(item)}
    />
  </div>
);

export const EPIAssigneeCellEditor = (props: any) => {
  const agGridData = useSelector((state: RootState) => state.agGrid.data);
  const isFullRowEdit = agGridData.editType === "fullRow";
  const [displayedAssignee, setDisplayedAssignee] = useState(props.value || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [inputVisible, setInputVisible] = useState(false);
  const [newAssignee, setNewAssignee] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const reducerAllAssignees = useSelector(
    (state: RootState) => state.executionProjectIssue.uniqueAssignees
  );
  const [allAssigneesList, setAllAssigneeList] = useState(reducerAllAssignees);
  const [availableAssigneeToSearch, setAvailableAssigneeToSearch] = useState(
    () =>
      allAssigneesList.filter(
        (assignee: string) => !displayedAssignee?.includes(assignee)
      )
  );
  const [filteredAssignee, setFilteredAssignees] = useState(
    availableAssigneeToSearch
  );
  useEffect(() => {
    const newAvailableAssignee = reducerAllAssignees.filter(
      (assignee: string) => !displayedAssignee.includes(assignee)
    );
    setAvailableAssigneeToSearch(newAvailableAssignee);
  }, [displayedAssignee, reducerAllAssignees]);
  useEffect(() => {
    setAllAssigneeList(reducerAllAssignees);
  }, [reducerAllAssignees]);
  useEffect(() => {
    setFilteredAssignees(availableAssigneeToSearch);
  }, [availableAssigneeToSearch]);

  const handleScroll = () => {
    setDropdownVisible(false); // Close the dropdown on scroll
  };
  useEffect(() => {
    const verticalScrollContainer = document.querySelector(".ag-body-viewport"); // Vertical scroll container
    const horizontalScrollContainer = document.querySelector(
      ".ag-center-cols-viewport"
    ); // Horizontal scroll container

    const attachListeners = () => {
      verticalScrollContainer?.addEventListener("scroll", handleScroll);
      horizontalScrollContainer?.addEventListener("scroll", handleScroll);
    };

    const detachListeners = () => {
      verticalScrollContainer?.removeEventListener("scroll", handleScroll);
      horizontalScrollContainer?.removeEventListener("scroll", handleScroll);
    };

    if (dropdownVisible) {
      attachListeners();
    } else {
      detachListeners();
    }

    return () => {
      detachListeners(); // Clean up on component unmount or when dependencies change
    };
  }, [dropdownVisible]);
  const calculateDropdownPosition = () => {
    if (!editorRef.current) return { top: 0, left: 0, width: 0 };

    const rect = editorRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom; // Space below the trigger
    const spaceAbove = rect.top; // Space above the trigger
    let topPosition = rect.bottom;
    if (spaceBelow < literals.dropdownHeight300) {
      // If there isn't enough space below, open the dropdown above
      topPosition = spaceAbove - literals.dropdownHeight300 + 15; // Adjust height as needed
    }
    return {
      top: topPosition,
      left: rect.left,
      width: rect.width,
    };
  };
  const handleToggleDropdown = () => {
    if (!dropdownVisible) {
      const position = calculateDropdownPosition();
      setDropdownPosition(position);
    }
    setDropdownVisible((prev) => !prev);
  };

  const handleAssigneeAddition = (assigneeToAdd: string) => {
    const isAssigneeExist = checkItemExistence(
      displayedAssignee,
      assigneeToAdd
    );
    if (isAssigneeExist) toast.error(literals.assigneeAlreadyExist);
    else {
      const updatedAssignees = [...displayedAssignee, assigneeToAdd];
      saveDataToGrid(updatedAssignees);
    }
  };

  const adjustRowHeight = useCallback(() => {
    if (editorRef.current) {
      const contentHeight = editorRef.current.scrollHeight;
      const currentRowHeight = props.node.rowHeight;

      if (contentHeight > currentRowHeight) {
        props.node.setRowHeight(contentHeight + 12); // Set new row height
        props.api.onRowHeightChanged(); // Notify grid to adjust row height
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.node, props.api, inputVisible]);

  useEffect(() => {
    adjustRowHeight();
  }, [displayedAssignee, adjustRowHeight]);

  const saveDataToGrid = (updatedAssignees: string[]) => {
    setDisplayedAssignee(updatedAssignees);
    endEditMode();
    props.onValueChange(updatedAssignees);
    if (!isFullRowEdit) {
      props.stopEditing();
    }
  };

  const endEditMode = () => {
    setNewAssignee("");
    setInputVisible(false);
    setDropdownVisible(false);
  };
  const handleSearch = (searchTerm: string) => {
    const searchedItems = filterItemsBySearchString(
      availableAssigneeToSearch,
      searchTerm
    );
    setFilteredAssignees(searchedItems);
  };

  const handleAssigneeRemove = (removeItem: string) => {
    const updatedAssignees = displayedAssignee.filter(
      (item: string) => item !== removeItem
    );
    saveDataToGrid(updatedAssignees);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const assigneeToAdd = newAssignee.trim();
      if (assigneeToAdd) {
        handleAssigneeAddition(assigneeToAdd);
        e.stopPropagation();
      } else {
        endEditMode();
        e.stopPropagation();
      }
    } else if (e.key === "Escape") {
      endEditMode();
    }
  };

  const renderDropdown = () => (
    <div
      className="epi-assignee-search-container"
      ref={dropdownRef}
      style={{
        top: `${dropdownPosition.top}px`, // Dynamic top position
        left: `${dropdownPosition.left}px`, // Dynamic left position
        width: `${dropdownPosition.width}px`,
        height: literals.dropdownHeight300 - 20,
      }}
    >
      <div className="assignees-header">
        <Text
          text={literals.existingAssignee}
          color={colors.black}
          styleName="Heading6"
        />
        <img
          className="cross-icon"
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => setDropdownVisible(false)}
        />
      </div>
      <div className="assignees-search-div">
        <InputGroup className="search-input" size="lg">
          <Form.Control
            aria-label="Large"
            value={searchTerm || ""}
            aria-describedby="inputGroup-sizing-sm"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <Button
            className="clear-search-button"
            disabled={!searchTerm}
            onClick={() => {
              setSearchTerm("");
              handleSearch("");
            }}
          >
            <img
              className="clear-search-icon"
              src={CrossIcon}
              alt="cross-icon"
            />
          </Button>
        </InputGroup>
        <Button
          className="search-button"
          onClick={() => handleSearch(searchTerm)}
        >
          {literals.search}
        </Button>
      </div>
      <div className="assignees-content-container">
        {filteredAssignee.length > 0 ? (
          filteredAssignee.map((assignee: any, index: any) => (
            <div
              key={`dropdown_${index}`}
              className="caption2 assignees-content-item"
              onClick={() => handleAssigneeAddition(assignee)}
            >
              {assignee}
            </div>
          ))
        ) : (
          <div className="dropdown-empty">{literals.noAssigneesAvailable}</div>
        )}
      </div>
    </div>
  );
  return (
    <div className={`epi-Assignee-wrapper`} ref={editorRef}>
      {
        <div className="editable-wrapper">
          <div className="left-div">
            {displayedAssignee.map((item: string, index: number) =>
              renderAssigneeItem(item, index, handleAssigneeRemove)
            )}
            {inputVisible && (
              <input
                ref={inputRef}
                type="text"
                className="add-assignee-input"
                value={newAssignee}
                onChange={(e) => setNewAssignee(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            )}
          </div>
          <div className="right-div">
            <div className="add-assignee-wrapper">
              {
                <img
                  className="plus-icon"
                  src={PlusIcon}
                  alt="plus-icon"
                  onClick={() => {
                    setNewAssignee("");
                    setInputVisible(true);
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                />
              }
              <img
                className="dropdown-icon"
                src={FilledDropDown}
                alt="dropdown-icon"
                onClick={handleToggleDropdown}
              />
              {dropdownVisible &&
                ReactDOM.createPortal(renderDropdown(), document.body)}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export const EPIAssigneeCellRender = (params: any) => {
  const [displayedAssignee, setDisplayedAssignee] = useState(
    params?.value || []
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showAll, setShowAll] = useState(false);
  const [visibleCount, setVisibleCount] = useState(displayedAssignee.length);
  const handleShowAll = () => setShowAll(true);
  const handleAssigneeRemove = (removeItem: string) => {
    const updatedTopics = displayedAssignee.filter(
      (item: string) => item !== removeItem
    );
    params.node.setDataValue(params.colDef.field, updatedTopics);
  };

  useEffect(() => {
    setDisplayedAssignee(params?.value || []);
  }, [params?.value]);
  useEffect(() => {
    if (!containerRef.current || showAll) return;
    const calculateVisibleCount = () => {
      const container = containerRef.current!;
      const items = Array.from(container.children) as HTMLElement[];
      let totalWidth = 0;
      let count = 0;

      for (const item of items) {
        totalWidth += item.offsetWidth;
        if (totalWidth <= literals.cellWidth300 - 25) {
          count++;
        } else {
          break;
        }
      }

      // If all strings fit in one line, show all of them
      if (count === displayedAssignee.length) {
        setVisibleCount(displayedAssignee.length);
      } else {
        // Otherwise, show as many as possible with space for "+X more"
        setVisibleCount(count - 1);
      }
    };

    calculateVisibleCount();
  }, [displayedAssignee, showAll]);
  return (
    <>
      {displayedAssignee.length === 0 ? (
        <span></span>
      ) : (
        <div
          ref={containerRef}
          className={`epi-assignee-content-clip-wrapper ${
            showAll ? "wrap" : ""
          }`}
          style={{
            width: `${literals.cellWidth300}px`,
          }}
        >
          {displayedAssignee
            .slice(0, showAll ? displayedAssignee.length : visibleCount)
            .map((item: string, index: number) =>
              renderAssigneeItem(item, index, handleAssigneeRemove)
            )}
          {!showAll && visibleCount < displayedAssignee.length && (
            <Button
              onClick={handleShowAll}
              variant="link"
              className="more-link-button"
            >
              +{displayedAssignee.length - visibleCount}{" "}
              {displayedAssignee.length - visibleCount > 1 ? "others" : "other"}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
