import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectData {
  data: any[];
}

const initialState: ProjectData = { data: [] };

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectData: (state, action: PayloadAction<any[] | null>) => {
      state.data = action.payload ?? [];
    },
    clearProjectData: (state) => {
      state.data = [];
    },
  },
});

export const { setProjectData, clearProjectData } = projectSlice.actions;

export default projectSlice.reducer;