import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ExecutionProjectSummaryQueryData {
  data: any;
  uniqueApprovers: string[];
  uniqueContributors: string[];
  uniqueInformed: string[];
}

const initialState: ExecutionProjectSummaryQueryData = {
  data: [],
  uniqueApprovers: [],
  uniqueContributors: [],
  uniqueInformed: [],
};

const executionProjectSummaryQuerySlice = createSlice({
  name: "executionProjectSummaryQuery",
  initialState,
  reducers: {
    setExecutionProjectSummaryQueryData: (
      state,
      action: PayloadAction<ExecutionProjectSummaryQueryData | null>
    ) => {
      return action.payload ?? initialState;
    },
  },
});

export const { setExecutionProjectSummaryQueryData } = executionProjectSummaryQuerySlice.actions;

export default executionProjectSummaryQuerySlice.reducer;
