import "../../../styles/page/ClientDetails/TenantDetails/DetractorTopicCell.scss";
import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from "react";
import PlusIcon from "../../../assets/images/PlusIcon.svg";
import CrossIcon from "../../../assets/images/cross.png";
import FilledDropDown from "../../../assets/images/FilledDropDown.svg";
import { Button, InputGroup, Form } from "react-bootstrap";
import "../../../sass/typography.scss";
import { literals } from "../../../theme/literals";
import Text from "../../../component/Text/Text";
import { colors } from "../../../theme/colors";
import {
  checkTopicExistance,
  filterTopicBySearchString,
} from "../utils/DetractorCellHelper";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../../store";
export const DetractorTopicCell = (params: any) => {
  const [displayedTopic, setDisplayedTopic] = useState(params.value || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [newTopic, setNewTopic] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [visibleCount, setVisibleCount] = useState(displayedTopic.length);
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const reducerAllTopics = useSelector(
    (state: RootState) => state.insight.uniqueTopic
  );
  const [allTopicsList, setAllTopicList] = useState(reducerAllTopics);
  const [availableTopicToSearch, setAvailableTopicToSearch] = useState(() =>
    allTopicsList.filter((topic: string) => !displayedTopic?.includes(topic))
  );
  const [filteredTopic, setFilterdTopics] = useState(availableTopicToSearch);
  useEffect(() => {
    const newAvailableTopic = reducerAllTopics.filter(
      (tag: string) => !displayedTopic.includes(tag)
    );
    setAvailableTopicToSearch(newAvailableTopic);
  }, [displayedTopic, reducerAllTopics]);
  useEffect(() => {
    setAllTopicList(reducerAllTopics);
  }, [reducerAllTopics]);
  useEffect(() => {
    setFilterdTopics(availableTopicToSearch);
  }, [availableTopicToSearch]);
  useEffect(() => {
    if (!containerRef.current || showAll) return;

    const calculateVisibleCount = () => {
      const container = containerRef.current!;
      const items = Array.from(container.children) as HTMLElement[];
      let totalWidth = 0;
      let count = 0;

      for (const item of items) {
        totalWidth += item.offsetWidth;
        if (totalWidth <= literals.detractorTopicCellWidth - 25) {
          count++;
        } else {
          break;
        }
      }

      // If all strings fit in one line, show all of them
      if (count === displayedTopic.length) {
        setVisibleCount(displayedTopic.length);
      } else {
        // Otherwise, show as many as possible with space for "+X more"
        setVisibleCount(count - 1);
      }
    };

    calculateVisibleCount();
  }, [displayedTopic, showAll]);

  const handleClickOutside = (event: any) => {
    if (
      event.target.classList.contains("tags-content-topic") ||
      event.target.closest(".tags-search-container") // If it's a child of the div with this class
    ) {
      return; // Do nothing
    } else if (
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      endEditMode();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateDropdownPosition = () => {
    if (!triggerRef.current) return { top: 0, left: 0, width: 0 };

    const rect = triggerRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom; // Space below the trigger
    const spaceAbove = rect.top; // Space above the trigger
    let topPosition = rect.bottom;
    if (spaceBelow < literals.detractorTopicDropdownHeight) {
      // If there isn't enough space below, open the dropdown above
      topPosition = spaceAbove - literals.detractorTopicDropdownHeight + 15; // Adjust height as needed
    }
    return {
      top: topPosition,
      left: rect.left,
      width: rect.width,
    };
  };

  const handleToggleDropdown = () => {
    if (!dropdownVisible) {
      const position = calculateDropdownPosition();
      setDropdownPosition(position);
    }
    setDropdownVisible((prev) => !prev);
  };

  const handleShowAll = () => setShowAll(true);

  const handleTopicAddition = (topicToAdd: string) => {
    const isTopicExist = checkTopicExistance(displayedTopic, topicToAdd);
    if (isTopicExist) toast.error(literals.topicAlreadyExist);
    else {
      const updatedTopics = [...displayedTopic, topicToAdd];
      saveDataToGrid(updatedTopics);
    }
  };

  const saveDataToGrid = (updatedTopics: string[]) => {
    setDisplayedTopic(updatedTopics);
    endEditMode();
    params.node.setDataValue(params.colDef.field, updatedTopics);
  };

  const handleScroll = () => {
    setDropdownVisible(false); // Close the dropdown on scroll
  };
  useEffect(() => {
    const verticalScrollContainer = document.querySelector(".ag-body-viewport"); // Vertical scroll container
    const horizontalScrollContainer = document.querySelector(
      ".ag-center-cols-viewport"
    ); // Horizontal scroll container

    const attachListeners = () => {
      verticalScrollContainer?.addEventListener("scroll", handleScroll);
      horizontalScrollContainer?.addEventListener("scroll", handleScroll);
    };

    const detachListeners = () => {
      verticalScrollContainer?.removeEventListener("scroll", handleScroll);
      horizontalScrollContainer?.removeEventListener("scroll", handleScroll);
    };

    if (dropdownVisible) {
      attachListeners();
    } else {
      detachListeners();
    }

    return () => {
      detachListeners(); // Clean up on component unmount or when dependencies change
    };
  }, [dropdownVisible]);

  const endEditMode = () => {
    setNewTopic("");
    setInputVisible(false);
    setIsEditing(false);
    setDropdownVisible(false);
    setShowAll(false);
  };
  const handleSearch = (searchTerm: string) => {
    const searchedItems = filterTopicBySearchString(
      availableTopicToSearch,
      searchTerm
    );
    setFilterdTopics(searchedItems);
  };

  const handleTopicRemove = (removeItem: string) => {
    const updatedTopics = displayedTopic.filter(
      (item: string) => item !== removeItem
    );
    saveDataToGrid(updatedTopics);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const topicToAdd = newTopic.trim();
      if (topicToAdd) {
        handleTopicAddition(topicToAdd);
      } else {
        endEditMode();
      }
    } else if (e.key === "Escape") {
      endEditMode();
    }
  };

  const renderTopicItem = (item: string, index: number) => (
    <div key={`topic_${index}`} className="topic-content-wrapper">
      <span
        className={`caption2 ${
          isEditing && item?.length > 15 ? "break-item" : ""
        }`}
      >
        {item}
      </span>
      <img
        className="cross-icon"
        src={CrossIcon}
        alt="cross-icon"
        onClick={() => handleTopicRemove(item)}
      />
    </div>
  );

  const renderDropdown = () => (
    <div
      ref={dropdownRef}
      className="tags-search-container"
      style={{
        top: `${dropdownPosition.top}px`, // Dynamic top position
        left: `${dropdownPosition.left}px`, // Dynamic left position
        width: `${dropdownPosition.width}px`,
        height: literals.detractorTopicDropdownHeight - 20,
      }}
    >
      <div className="tags-header">
        <Text
          text={literals.existingTag}
          color={colors.black}
          styleName="Heading6"
        />
        <img
          className="cross-icon"
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => setDropdownVisible(false)}
        />
      </div>
      <div className="tags-search-div">
        <InputGroup className="search-input" size="lg">
          <Form.Control
            aria-label="Large"
            value={searchTerm || ""}
            aria-describedby="inputGroup-sizing-sm"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <Button
            className="clear-search-button"
            disabled={!searchTerm}
            onClick={() => {
              setSearchTerm("");
              handleSearch("");
            }}
          >
            <img
              className="clear-search-icon"
              src={CrossIcon}
              alt="cross-icon"
            />
          </Button>
        </InputGroup>
        <Button
          className="search-button"
          onClick={() => handleSearch(searchTerm)}
        >
          {literals.search}
        </Button>
      </div>
      <div className="tags-content-container">
        {filteredTopic.length > 0 ? (
          filteredTopic.map((tag: any, index: any) => (
            <div
              key={`dropdown_${index}`}
              className="caption2 tags-content-topic"
              onClick={() => handleTopicAddition(tag)}
            >
              {tag}
            </div>
          ))
        ) : (
          <div className="dropdown-empty">No tags available</div>
        )}
      </div>
    </div>
  );

  return (
    <div
      ref={triggerRef}
      className={`topic-wrapper ${isEditing ? "editable" : ""}`}
      onDoubleClick={handleDoubleClick}
    >
      {isEditing ? (
        <div className="editable-wrapper">
          <div className="left-div">
            {displayedTopic.map(renderTopicItem)}
            {inputVisible && (
              <input
                ref={inputRef}
                type="text"
                className="add-topic-input"
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            )}
          </div>
          <div className="right-div">
            <div className="add-tag-wrapper">
              {
                <img
                  className="plus-icon"
                  src={PlusIcon}
                  alt="plus-icon"
                  onClick={() => {
                    setNewTopic("");
                    setInputVisible(true);
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                />
              }
              <img
                className="dropdown-icon"
                src={FilledDropDown}
                alt="dropdown-icon"
                onClick={handleToggleDropdown}
              />
              {dropdownVisible &&
                ReactDOM.createPortal(renderDropdown(), document.body)}
            </div>
          </div>
        </div>
      ) : (
        <>
          {displayedTopic.length === 0 ? (
            <span></span>
          ) : (
            <div
              ref={containerRef}
              className={`content-clip-wrapper ${showAll ? "wrap" : ""}`}
              style={{
                width: `${literals.detractorTopicCellWidth}px`,
              }}
            >
              {displayedTopic
                .slice(0, showAll ? displayedTopic.length : visibleCount)
                .map(renderTopicItem)}
              {!showAll && visibleCount < displayedTopic.length && (
                <Button
                  onClick={handleShowAll}
                  variant="link"
                  className="more-link-button"
                >
                  +{displayedTopic.length - visibleCount}{" "}
                  {displayedTopic.length - visibleCount > 1
                    ? "others"
                    : "other"}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
