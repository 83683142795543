import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AgGridData {
  data: { editType: "fullRow" | null };
}
const initialState: AgGridData = { data: { editType: null } };
const AgGridQuerySlice = createSlice({
  name: "AgGridQuery",
  initialState,
  reducers: {
    setAgGridQueryData: (
      state,
      action: PayloadAction<{
        data: any;
      } | null>
    ) => {
      return action.payload ?? initialState;
    },
    clearAgGridQueryData: () => {
      return initialState;
    },
  },
});

export const { setAgGridQueryData, clearAgGridQueryData } =
  AgGridQuerySlice.actions;
export default AgGridQuerySlice.reducer;
