function NumberSpecialCharEditor() { }

NumberSpecialCharEditor.prototype.init = function (params: any) {
    this.eDiv = document.createElement('div');
    this.eDiv.style.position = 'absolute';
    this.eDiv.style.width = '100%';
    this.eDiv.style.height = '100%';
    this.eDiv.style.display = 'flex';


    this.eInput = document.createElement('input');
    this.eInput.type = 'text';
    this.eInput.style.border = 'none';
    this.eInput.style.outline = 'none';
    this.eInput.style.display = 'flex';
    this.eInput.style.flex = 1;
    this.eInput.style.padding = '0.5rem 0.5rem 0.5rem 0.5rem';

    this.eInput.value = params.value;

    // Add ag-Grid CSS classes to the input element
    this.eInput.classList.add('ag-input-field-input', 'ag-text-field-input');

    this.eDiv.appendChild(this.eInput);

    this.eInput.addEventListener('input', function (this: any, event: any) {
        const newValue = event.target.value;
        if (/^[0-9+\-()]*$/.test(newValue)) {
            this.value = newValue;
        } else {
            this.eInput.value = this.value || '';
        }
    }.bind(this));
};

NumberSpecialCharEditor.prototype.getGui = function () {
    return this.eDiv;
};

NumberSpecialCharEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

NumberSpecialCharEditor.prototype.getValue = function () {
    return this.eInput.value;
};

NumberSpecialCharEditor.prototype.destroy = function () { };

NumberSpecialCharEditor.prototype.isPopup = function () {
    return false;
};

export default NumberSpecialCharEditor;