import React from "react";
import { BackgroundColorByStatus } from "../../page/Admin/utils/globalUtil";
import "../../styles/component/ColoredStatusWrapper/ColoredStatusWrapper.scss";

const ColoredStatusWrapper = (params: any) => {
  const bgColor = BackgroundColorByStatus[params.value];
  return (
    <div
      className="single-select-content-wrapper"
      style={{ background: bgColor }}
    >
      {params.value}
    </div>
  );
};

export default ColoredStatusWrapper;
