import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const SurveyCrudQuery = createApi({
  reducerPath: "SurveyCrudQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getSurveyInfo: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/surveys/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) =>
        response.map((item) => ({
          ...item,
          customer: {
            id: item.customerId ?? null,
            name: item.customer ?? "none",
          },
        })),
    }),
    addSurveyInfo: builder.mutation({
      query: (data) => ({
        url: `/admin/surveys/tenants/${data.tenantId}`,
        method: "POST",
        body: getSurveyBody(data),
        responseHandler: (response) => response.text(),
      }),
    }),
    updateSurveyInfo: builder.mutation({
      query: (data) => ({
        url: `/admin/surveys/tenants/${data.tenantId}/${data.id}`,
        method: "PUT",
        body: getSurveyBody(data),
        responseHandler: (response) => response.text(),
      }),
    }),
    removeSurveyInfo: builder.mutation({
      query: ({ id, tenantId }) => ({
        url: `/admin/surveys/tenants/${tenantId}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSurveyInfoQuery,
  useAddSurveyInfoMutation,
  useUpdateSurveyInfoMutation,
  useRemoveSurveyInfoMutation,
} = SurveyCrudQuery;

const getSurveyBody = (data: any) => {
  data.customerId = data.customer.id ?? null;
  delete data.tenantId;
  delete data.customer;
  return data;
};
