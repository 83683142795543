import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlBaseQuery } from '../../api/graphqlBaseQuery';

export const NetBenefitsHomeQuery = createApi({
  reducerPath: 'NetBenefitsHomeQuery',
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getNetBenefitsSummary: builder.query<any, void>({
      query: () => ({
        body: gql`
        { 
          netBenefits {
            netBenefitsSummary {
              valueCaptured {
                amount
                currency
              }
              roi
            }
            netBenefitsMetrics {
              metricName
              trend
              value
              timeSeriesData {
                date
                value
              }
            }
          }
        }
        `,
      }),
      transformResponse: ({ netBenefits }: any) => netBenefits,
    }),
  }),
});

export const {
  useGetNetBenefitsSummaryQuery,
} = NetBenefitsHomeQuery;