import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const ExecutionProjectSummaryQuery = createApi({
  reducerPath: "ExecutionProjectSummaryQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getExecutionProjectSummary: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/project-summaries/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (
        response: any[]
      ): { resData: any[]; uniqueApprovers: any; uniqueContributors: any; uniqueInformed: any } => {
        const resData: any[] = [];
        const uniqueApprovers = new Set();
        const uniqueContributors = new Set();
        const uniqueInformed = new Set();
        response.forEach((item) => {
          resData.push({
            ...item,
            project: item.projectName
              ? {
                name: item.projectName,
                id: item.projectId,
              }
              : null,
          });
          if (Array.isArray(item.approvers)) {
            item.approvers.forEach((approver: string) => uniqueApprovers.add(approver));
          }
          if (Array.isArray(item.contributors)) {
            item.contributors.forEach((contributor: string) => uniqueContributors.add(contributor));
          }
          if (Array.isArray(item.informed)) {
            item.informed.forEach((inform: string) => uniqueInformed.add(inform));
          }
        });

        return {
          resData,
          uniqueApprovers: Array.from(uniqueApprovers),
          uniqueContributors: Array.from(uniqueContributors),
          uniqueInformed: Array.from(uniqueInformed),
        };
      },
    }),
    addExecutionProjectSummary: builder.mutation({
      query: (projectData) => {
        return {
          url: `/admin/project-summaries/tenants/${projectData.tenantId}`,
          method: "POST",
          body: getProjectSummaryBody(projectData),
        };
      },
    }),
    updateExecutionProjectSummary: builder.mutation({
      query: (projectData) => ({
        url: `/admin/project-summaries/tenants/${projectData.tenantId}/${projectData.id}`,
        method: "PUT",
        body: getProjectSummaryBody(projectData),
      }),
    }),
    removeExecutionProjectSummary: builder.mutation({
      query: ({ tenantId, id }) => ({
        url: `/admin/project-summaries/tenants/${tenantId}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetExecutionProjectSummaryQuery,
  useAddExecutionProjectSummaryMutation,
  useUpdateExecutionProjectSummaryMutation,
  useRemoveExecutionProjectSummaryMutation,
} = ExecutionProjectSummaryQuery;

const getProjectSummaryBody = (bodyData: any) => {
  const { tenantId, project, ...restData } = bodyData;
  const reqData = {
    ...restData,
    "projectId": project.id,
  };
  return reqData;
};
