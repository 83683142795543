import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";
import { EPITenantType } from "./ExecutionProjectIssues.type";

export const EPITenantQuery = createApi({
  reducerPath: "EPITenantQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getEPITenantData: builder.query({
      query: () => ({
        url: `/project-issues`,
        method: "GET",
      }),
      transformResponse: (response: EPITenantType[]) => response,
    }),
  }),
});

export const { useGetEPITenantDataQuery } = EPITenantQuery;
