import "../../../styles/page/ClientDetails/TenantDetails/YearQuarterSingleCellRenderer.scss";
import { useEffect, useState } from "react";
import CrossIcon from "../../../assets/images/cross.png";
import ArrowLeft from "../../../assets/images/arrowLeft.svg";
import ArrowRight from "../../../assets/images/arrowRight.svg";
import FilledDropDown from "../../../assets/images/FilledDropDown.svg";
import "../../../sass/typography.scss";
import Text from "../../../component/Text/Text";

// Quarter options
const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];

export const YearQuarterSingleCellRenderer = (params: any) => {
    const [selectedYearQuarter, setSelectedYearQuarter] = useState(params.value || null);
    const [fromField] = useState(params?.fromField || null);
    const [isEditing, setIsEditing] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState<number>(currentYear);
    const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null);

    useEffect(() => {
        let selectedValue = params?.value
        let previousValue = params?.data
        if (selectedValue != null) {
            setYear(selectedValue?.year)
            setSelectedQuarter(selectedValue?.quarter)
        } else if (previousValue?.year != null) {
            setYear(previousValue?.year)
            setSelectedQuarter(previousValue?.quarter)
            setSelectedYearQuarter({ year: previousValue?.year, quarter: previousValue?.quarter })
        } else {
            setSelectedQuarter(null)
        }
    }, [params])

    // Handle Year Change
    const handleYearChange = (change: number) => {
        setYear((prev) => prev + change);
        setSelectedQuarter(null); // Reset quarter selection when year changes
    };

    // Handle Quarter Selection
    const handleQuarterSelect = (quarter: string) => {
        setSelectedQuarter(quarter);
        let dataToSave = { year, quarter }
        saveDataToGridAnother(dataToSave)
    };

    const saveDataToGridAnother = (updatedYearQuarter: any) => {
        setSelectedYearQuarter(updatedYearQuarter)
        endEditMode();
        params.node.setDataValue(params.colDef.field, updatedYearQuarter);
    };

    const endEditMode = () => {
        setIsEditing(false);
        setDropdownVisible(false);
    };

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const renderTopicItem = (item: any) => (
        <div key={`Year_`} className="year-content-wrapper">
            <span className={`caption2 ${isEditing && item?.length > 15 ? "break-item" : ""}`}>
                {item.year + ' ' + item.quarter}
            </span>
        </div>
    );

    const renderDropdown = () => (
        <div className={`year-quarter-container ${fromField === "CustomMetricCell" ? 'year-custom-metric-cell' : 'year-net-benefit-cell'}`}>
            <div className="year-quarter-header">
                <img
                    src={ArrowLeft}
                    alt="Arrow Left icon"
                    className="arrow-img"
                    onClick={() => handleYearChange(-1)}
                />
                <Text
                    text={year.toString()}
                    styleName="year-text" />
                <img
                    src={ArrowRight}
                    alt="Arrow Right icon"
                    className="arrow-img"
                    onClick={() => handleYearChange(1)}
                />
                <img
                    src={CrossIcon}
                    alt="cross icon"
                    className="close-button"
                    onClick={() => setDropdownVisible(false)}
                />
            </div>

            {/* Quarter Selection */}
            <div className="radio-div">
                {QUARTERS.map((quarter) => (
                    <label key={quarter} className="radio-label">
                        <input
                            type="radio"
                            name="quarter"
                            className="radio-view"
                            value={quarter}
                            checked={selectedQuarter === quarter}
                            onChange={() => handleQuarterSelect(quarter)}
                        />
                        {quarter}
                    </label>
                ))}
            </div>
        </div>
    );

    return (
        <div
            className={`year-wrapper ${isEditing ? "editable" : ""}`}
            onDoubleClick={handleDoubleClick}>
            {isEditing ? (
                <div className="year-editable-wrapper">
                    <div className="year-left-div">
                        {selectedYearQuarter != null && renderTopicItem(selectedYearQuarter)}
                    </div>
                    <div className="year-right-div">
                        <div className="add-tag-wrapper">
                            <img
                                className="dropdown-icon"
                                src={FilledDropDown}
                                alt="dropdown-icon"
                                onClick={() => setDropdownVisible(!dropdownVisible)}
                            />
                            {dropdownVisible && renderDropdown()}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {selectedYearQuarter === null ? (
                        <span></span>
                    ) : (
                        <div className={`content-clip-wrapper`}>
                            {renderTopicItem(selectedYearQuarter)}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
