import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const NetBenefitsQuery = createApi({
  reducerPath: "NetBenefitsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getNetBenefitsCompletedProjects: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/projects/tenants/${tenantId}/completed`,
        method: "GET",
      }),
      transformResponse: (response: any) =>
        response.map((item: any) => ({
          ...item,
        })),
    }),
    getNetBenefits: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/net-benefits/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any) =>
        response.map((item: any) => ({
          ...item,
          project: {
            projectId: item?.projectId,
            projectName: item?.projectName
          },
          valueCapturedAmount: item?.valueCaptured?.amount || '',
          valueCapturedCurrency: item?.valueCaptured?.currency || 'USD',
          timeline: {
            year: item?.year,
            quarter: item?.quarter
          }
        })),
    }),
    addNetBenefits: builder.mutation({
      query: (netBenefitsData) => ({
        url: `/admin/net-benefits/tenants/${netBenefitsData.tenantId}`,
        method: "POST",
        body: getNetBenefitsBody(netBenefitsData),
        responseHandler: (response) => response.text(),
      }),
    }),
    updateNetBenefits: builder.mutation({
      query: (netBenefitsData) => ({
        url: `/admin/net-benefits/tenants/${netBenefitsData.tenantId}`,
        method: "POST",
        body: getNetBenefitsBody(netBenefitsData),
      }),
    }),
    removeNetBenefits: builder.mutation({
      query: ({ id, tenantId }) => ({
        url: `/admin/net-benefits/tenants/${tenantId}/${id}`,
        method: "DELETE",
      }),
    }),
    getOverallROI: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/net-benefits/roi/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any) => response
    }),
    addOverallROI: builder.mutation({
      query: (netBenefitsData) => ({
        url: `/admin/net-benefits/roi/tenants/${netBenefitsData.tenantId}`,
        method: "POST",
        body: getOverallROIBody(netBenefitsData),
      }),
    }),
    updateOverallROI: builder.mutation({
      query: (netBenefitsData) => ({
        url: `/admin/net-benefits/roi/tenants/${netBenefitsData.tenantId}`,
        method: "PUT",
        body: getOverallROIBody(netBenefitsData),
      }),
    }),
    deleteOverallROI: builder.mutation({
      query: (netBenefitsData) => ({
        url: `/admin/net-benefits/roi/tenants/${netBenefitsData.tenantId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetNetBenefitsCompletedProjectsQuery,
  useGetNetBenefitsQuery,
  useAddNetBenefitsMutation,
  useUpdateNetBenefitsMutation,
  useRemoveNetBenefitsMutation,
  useGetOverallROIQuery,
  useAddOverallROIMutation,
  useUpdateOverallROIMutation,
  useDeleteOverallROIMutation
} = NetBenefitsQuery;

const getNetBenefitsBody = (netBenefitsData: any) => {
  let netBenefitsDataReq = {
    ...netBenefitsData,
    "projectId": netBenefitsData.project.projectId,
    "projectName": netBenefitsData.project.projectName,
    "valueCaptured": {
      "amount": netBenefitsData.valueCapturedAmount,
      "currency": netBenefitsData.valueCapturedCurrency
    },
    "quarter": netBenefitsData?.timeline?.quarter || null,
    "year": netBenefitsData?.timeline?.year || null,
  }

  delete netBenefitsDataReq.tenantId;
  delete netBenefitsDataReq.project;
  delete netBenefitsDataReq.timeline;
  delete netBenefitsDataReq.valueCapturedAmount;
  delete netBenefitsDataReq.valueCapturedCurrency;
  return netBenefitsDataReq;
};

const getOverallROIBody = (data: any) => {
  return { roi: data.roi };
};