import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InsightsDetractorQuerySlice {
  data: any;
}
const initialState: InsightsDetractorQuerySlice = { data: [] };
const insightDetractorQuerySlice = createSlice({
  name: "InsightsDetractorQuery",
  initialState,
  reducers: {
    setInsightsDetractorQueryData: (
      state,
      action: PayloadAction<{
        data: any;
      } | null>
    ) => {
      return action.payload ?? initialState;
    },
    clearInsightsDetractorQueryData: () => {
      return initialState;
    },
  },
});

export const { setInsightsDetractorQueryData, clearInsightsDetractorQueryData } =
  insightDetractorQuerySlice.actions;

export default insightDetractorQuerySlice.reducer;
