import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";
import { insightTimeFrame } from "../../../constants/ClientDetails";

export const InsightsQuery = createApi({
  reducerPath: "InsightsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getInsights: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/insights/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (
        response: any[]
      ): { resData: any[]; uniqueTopics: any } => {
        const resData: any[] = [];
        const uniqueTopics = new Set();
        response.forEach((item) => {
          resData.push({
            ...item,
            insightImplementationTimeFrame: item.insightImplementationTimeFrame
              ? {
                  key: item.insightImplementationTimeFrame,
                  value: insightTimeFrame[item.insightImplementationTimeFrame],
                }
              : null,
          });
          if (Array.isArray(item.detractorTopics)) {
            item.detractorTopics.forEach((topic: string) =>
              uniqueTopics.add(topic)
            );
          }
        });

        return { resData, uniqueTopics: Array.from(uniqueTopics) };
      },
    }),
    addInsights: builder.mutation({
      query: (insightsData) => ({
        url: `/admin/insights/tenants/${insightsData.tenantId}`,
        method: "POST",
        body: getInsightsBody(insightsData),
        responseHandler: (response) => response.text(),
      }),
    }),
    updateInsights: builder.mutation({
      query: (insightsData) => ({
        url: `/admin/insights/tenants/${insightsData.tenantId}/${insightsData.id}`,
        method: "PUT",
        body: getInsightsBody(insightsData),
        responseHandler: (response) => response.text(),
      }),
    }),
    removeInsights: builder.mutation({
      query: ({ id, tenantId }) => ({
        url: `/admin/insights/tenants/${tenantId}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetInsightsQuery,
  useAddInsightsMutation,
  useUpdateInsightsMutation,
  useRemoveInsightsMutation,
} = InsightsQuery;

const getInsightsBody = (insightsData: any) => {
  const {
    importance,
    severity,
    frequency,
    businessValue,
    implementationEase,
    strategicAlignment,
  } = insightsData;

  const customerCentricScore = importance + severity + frequency;
  const orgCentricScore =
    businessValue + implementationEase + strategicAlignment;
  const overallScore = customerCentricScore + orgCentricScore;

  delete insightsData.tenantId;
  return {
    ...insightsData,
    insightImplementationTimeFrame:
      insightsData.insightImplementationTimeFrame.key,
    customerCentricScore,
    orgCentricScore,
    overallScore,
  };
};
