import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";
import { getDateFromQuarterAndYear, getYearQuarterFromDate } from "../utils/DateConverter";

export const CustomMetricsQuery = createApi({
  reducerPath: "CustomMetricsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCustomMetrics: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any) =>
        response.map((item: any) => ({
          ...item,
          values: item.values.map((i: any, index: any) => {
            let { year, quarter } = getYearQuarterFromDate(i.timeFrame)
            return {
              id: i.id,
              ...i,
              timeline: {
                year,
                quarter
              }
            }
          })
        })),
    }),
    addCustomMetricOnly: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}`,
        method: "POST",
        body: getAddCustomMetricsBody(customMetricsData),
      }),
    }),
    updateCustomMetricOnly: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}`,
        method: "POST",
        body: getUpdateCustomMetricsBody(customMetricsData),
      }),
    }),
    addCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}`,
        method: "POST",
        body: getCustomMetricsBody(customMetricsData, true),
      }),
    }),
    updateCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}/${customMetricsData.metric.id}`,
        method: "PUT",
        body: getCustomMetricsBody(customMetricsData, false),
      }),
    }),
    removeCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}/${customMetricsData.metric.id}`,
        method: "PUT",
        body: getCustomMetricsRemoveBody(customMetricsData),
      }),
    }),
  }),
});

export const {
  useGetCustomMetricsQuery,
  useAddCustomMetricOnlyMutation,
  useUpdateCustomMetricOnlyMutation,
  useAddCustomMetricsMutation,
  useUpdateCustomMetricsMutation,
  useRemoveCustomMetricsMutation,
} = CustomMetricsQuery;

const getCustomMetricsBody = (customMetricsData: any, isFromAdd: boolean) => {
  let data = customMetricsData.previousData.filter((item: any) => item.id === customMetricsData.metric.id)
  data = data[0];

  let updatedData: any;
  if (isFromAdd) {
    //For add
    if (data) {
      updatedData = {
        ...data,
        values: [
          ...data?.values,
          {
            id: null,
            timeFrame: getDateFromQuarterAndYear(customMetricsData.timeline.year, customMetricsData.timeline.quarter),
            value: customMetricsData.value
          }],
      }
    } else {
      updatedData = {
        ...customMetricsData.metric,
        values: [
          {
            id: null,
            timeFrame: getDateFromQuarterAndYear(customMetricsData.timeline.year, customMetricsData.timeline.quarter),
            value: customMetricsData.value
          }],
      }
    }
  } else {
    //For Update
    let updatedValues = data?.values?.map((item: any) =>
      item.id === customMetricsData.id
        ? {
          ...item,
          timeFrame: getDateFromQuarterAndYear(customMetricsData.timeline.year, customMetricsData.timeline.quarter),
          value: customMetricsData.value
        }
        : item
    );

    updatedData = {
      ...data,
      values: updatedValues
    };
  }

  // Replace the old data in previousData with the updated data
  customMetricsData.previousData = customMetricsData?.previousData?.map((item: any) =>
    item.id === customMetricsData.metric.id ? {
      ...updatedData,
      // Ensure only timeFrame and value are included in the values array
      values: updatedData.values.map((v: any) => ({
        id: v.id,
        timeFrame: v.timeFrame,
        value: v.value
      }))
    } : item
  );

  updatedData = {
    ...updatedData,
    values: updatedData?.values?.map((v: any) => ({
      id: v.id,
      timeFrame: v.timeFrame,
      value: v.value
    }))
  }

  return updatedData;
};

const getCustomMetricsRemoveBody = (customMetricsData: any) => {
  let data = customMetricsData?.previousData?.filter((item: any) => item.id === customMetricsData?.metric?.id)
  data = data[0];
  let updatedValues = data?.values?.filter((item: any) => item.id !== customMetricsData?.id);

  let updatedData = {
    ...data,
    values: updatedValues
  };

  return updatedData;
}

const getAddCustomMetricsBody = (customMetricsData: any) => {
  return customMetricsData?.metric;
}

const getUpdateCustomMetricsBody = (customMetricsData: any) => {
  let data = customMetricsData?.previousData?.filter((item: any) => item.id === customMetricsData?.metric?.id)
  data = data[0];
  let updatedData = {
    ...data,
    active: customMetricsData?.metric?.active
  };

  return updatedData;
}