import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PlusIcon from "../../../../assets/images/PlusIcon.svg";
import CrossIcon from "../../../../assets/images/cross.png";
import FilledDropDown from "../../../../assets/images/FilledDropDown.svg";
import { Button, InputGroup, Form } from "react-bootstrap";
import { checkItemExistence, filterItemsBySearchString } from "../../utils/ExecutionProjectIssueHelper";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../../../store";
import { literals } from "../../../../theme/literals";
import Text from "../../../../component/Text/Text";
import { colors } from "../../../../theme/colors";
import "../../../../styles/page/ExecutionProjectSummary/EPSApproverCell.scss";
import "../../../../sass/typography.scss";

const renderApproverItem = (
  item: string,
  index: number,
  handleApproverRemove: any
) => (
  <div key={`approver_${index}`} className="eps-approver-content-wrapper">
    <span className={`caption2 ${item?.length > 15 ? "break-item" : ""}`}>
      {item}
    </span>
    <img
      className="cross-icon"
      src={CrossIcon}
      alt="cross-icon"
      onClick={() => handleApproverRemove(item)}
    />
  </div>
);

export const EPSApproverCellEditor = (props: any) => {
  const agGridData = useSelector((state: RootState) => state.agGrid.data);
  const isFullRowEdit = agGridData.editType === "fullRow";
  const [displayedApprovers, setDisplayedApprovers] = useState(props.value || []);
  const inputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [inputVisible, setInputVisible] = useState(false);
  const [newApprover, setNewApprover] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const reducerAllApprovers = useSelector((state: RootState) => state.executionProjectSummary.uniqueApprovers);
  const [allApproversList, setAllApproversList] = useState(reducerAllApprovers);
  const [availableApproversToSearch, setAvailableApproversToSearch] = useState(
    () => allApproversList.filter((approver: string) => !displayedApprovers?.includes(approver))
  );
  const [filteredApprovers, setFilteredApprovers] = useState(availableApproversToSearch);

  useEffect(() => {
    const newAvailableApprovers = reducerAllApprovers.filter(
      (approver: string) => !displayedApprovers.includes(approver)
    );
    setAvailableApproversToSearch(newAvailableApprovers);
  }, [displayedApprovers, reducerAllApprovers]);

  useEffect(() => {
    setAllApproversList(reducerAllApprovers);
  }, [reducerAllApprovers]);

  useEffect(() => {
    setFilteredApprovers(availableApproversToSearch);
  }, [availableApproversToSearch]);

  const handleScroll = () => {
    setDropdownVisible(false); // Close the dropdown on scroll
  };

  useEffect(() => {
    const verticalScrollContainer = document.querySelector(".ag-body-viewport"); // Vertical scroll container
    const horizontalScrollContainer = document.querySelector(
      ".ag-center-cols-viewport"
    ); // Horizontal scroll container

    const attachListeners = () => {
      verticalScrollContainer?.addEventListener("scroll", handleScroll);
      horizontalScrollContainer?.addEventListener("scroll", handleScroll);
    };

    const detachListeners = () => {
      verticalScrollContainer?.removeEventListener("scroll", handleScroll);
      horizontalScrollContainer?.removeEventListener("scroll", handleScroll);
    };

    if (dropdownVisible) {
      attachListeners();
    } else {
      detachListeners();
    }

    return () => {
      detachListeners(); // Clean up on component unmount or when dependencies change
    };
  }, [dropdownVisible]);

  const calculateDropdownPosition = () => {
    if (!editorRef.current) return { top: 0, left: 0, width: 0 };

    const rect = editorRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom; // Space below the trigger
    const spaceAbove = rect.top; // Space above the trigger
    let topPosition = rect.bottom;
    if (spaceBelow < literals.dropdownHeight300) {
      // If there isn't enough space below, open the dropdown above
      topPosition = spaceAbove - literals.dropdownHeight300 + 15; // Adjust height as needed
    }
    return {
      top: topPosition,
      left: rect.left,
      width: rect.width,
    };
  };

  const handleToggleDropdown = () => {
    if (!dropdownVisible) {
      const position = calculateDropdownPosition();
      setDropdownPosition(position);
    }
    setDropdownVisible((prev) => !prev);
  };

  const handleApproverAddition = (approverToAdd: string) => {
    const isApproverExist = checkItemExistence(
      displayedApprovers,
      approverToAdd
    );
    if (isApproverExist) toast.error(literals.approverAlreadyExist);
    else {
      const updatedApprovers = [...displayedApprovers, approverToAdd];
      saveDataToGrid(updatedApprovers);
    }
  };

  const adjustRowHeight = useCallback(() => {
    if (editorRef.current) {
      const contentHeight = editorRef.current.scrollHeight;
      const currentRowHeight = props.node.rowHeight;

      if (contentHeight > currentRowHeight) {
        props.node.setRowHeight(contentHeight + 12); // Set new row height
        props.api.onRowHeightChanged(); // Notify grid to adjust row height
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.node, props.api, inputVisible]);

  useEffect(() => {
    adjustRowHeight();
  }, [displayedApprovers, adjustRowHeight]);

  const saveDataToGrid = (updatedApprovers: string[]) => {
    setDisplayedApprovers(updatedApprovers);
    endEditMode();
    props.onValueChange(updatedApprovers);
    if (!isFullRowEdit) {
      props.stopEditing();
    }
  };

  const endEditMode = () => {
    setNewApprover("");
    setInputVisible(false);
    setDropdownVisible(false);
  };

  const handleSearch = (searchTerm: string) => {
    const searchedItems = filterItemsBySearchString(
      availableApproversToSearch,
      searchTerm
    );
    setFilteredApprovers(searchedItems);
  };

  const handleApproverRemove = (removeItem: string) => {
    const updatedApprovers = displayedApprovers.filter(
      (item: string) => item !== removeItem
    );
    saveDataToGrid(updatedApprovers);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const approverToAdd = newApprover.trim();
      if (approverToAdd) {
        handleApproverAddition(approverToAdd);
        e.stopPropagation();
      } else {
        endEditMode();
        e.stopPropagation();
      }
    } else if (e.key === "Escape") {
      endEditMode();
    }
  };

  const renderDropdown = () => (
    <div
      className="eps-approver-search-container"
      ref={dropdownRef}
      style={{
        top: `${dropdownPosition.top}px`, // Dynamic top position
        left: `${dropdownPosition.left}px`, // Dynamic left position
        width: `${dropdownPosition.width}px`,
        height: literals.dropdownHeight300 - 20,
      }}
    >
      <div className="approvers-header">
        <Text
          text={literals.existingApprover}
          color={colors.black}
          styleName="Heading6"
        />
        <img
          className="cross-icon"
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => setDropdownVisible(false)}
        />
      </div>
      <div className="approvers-search-div">
        <InputGroup className="search-input" size="lg">
          <Form.Control
            aria-label="Large"
            value={searchTerm || ""}
            aria-describedby="inputGroup-sizing-sm"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <Button
            className="clear-search-button"
            disabled={!searchTerm}
            onClick={() => {
              setSearchTerm("");
              handleSearch("");
            }}
          >
            <img
              className="clear-search-icon"
              src={CrossIcon}
              alt="cross-icon"
            />
          </Button>
        </InputGroup>
        <Button
          className="search-button"
          onClick={() => handleSearch(searchTerm)}
        >
          {literals.search}
        </Button>
      </div>
      <div className="approvers-content-container">
        {filteredApprovers.length > 0 ? (
          filteredApprovers.map((approver: any, index: any) => (
            <div
              key={`dropdown_${index}`}
              className="caption2 approvers-content-item"
              onClick={() => handleApproverAddition(approver)}
            >
              {approver}
            </div>
          ))
        ) : (
          <div className="dropdown-empty body1">{literals.noApproversAvailable}</div>
        )}
      </div>
    </div>
  );

  return (
    <div className={`eps-approver-wrapper`} ref={editorRef}>
      {
        <div className="editable-wrapper">
          <div className="left-div">
            {displayedApprovers.map((item: string, index: number) =>
              renderApproverItem(item, index, handleApproverRemove)
            )}
            {inputVisible && (
              <input
                ref={inputRef}
                type="text"
                className="add-approver-input"
                value={newApprover}
                onChange={(e) => setNewApprover(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            )}
          </div>
          <div className="right-div">
            <div className="add-approver-wrapper">
              {
                <img
                  className="plus-icon"
                  src={PlusIcon}
                  alt="plus-icon"
                  onClick={() => {
                    setNewApprover("");
                    setInputVisible(true);
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                />
              }
              <img
                className="dropdown-icon"
                src={FilledDropDown}
                alt="dropdown-icon"
                onClick={handleToggleDropdown}
              />
              {dropdownVisible &&
                ReactDOM.createPortal(renderDropdown(), document.body)}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export const EPSApproverCellRender = (params: any) => {
  const [displayedApprovers, setDisplayedApprovers] = useState(params?.value || []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showAll, setShowAll] = useState(false);
  const [visibleCount, setVisibleCount] = useState(displayedApprovers.length);
  const handleShowAll = () => setShowAll(true);
  const handleApproverRemove = (removeItem: string) => {
    const updatedTopics = displayedApprovers.filter(
      (item: string) => item !== removeItem
    );
    params.node.setDataValue(params.colDef.field, updatedTopics);
  };

  useEffect(() => {
    setDisplayedApprovers(params?.value || []);
  }, [params?.value]);

  useEffect(() => {
    if (!containerRef.current || showAll) return;
    const calculateVisibleCount = () => {
      const container = containerRef.current!;
      const items = Array.from(container.children) as HTMLElement[];
      let totalWidth = 0;
      let count = 0;

      for (const item of items) {
        totalWidth += item.offsetWidth;
        if (totalWidth <= literals.cellWidth300 - 25) {
          count++;
        } else {
          break;
        }
      }

      // If all strings fit in one line, show all of them
      if (count === displayedApprovers.length) {
        setVisibleCount(displayedApprovers.length);
      } else {
        // Otherwise, show as many as possible with space for "+X more"
        setVisibleCount(count - 1);
      }
    };

    calculateVisibleCount();
  }, [displayedApprovers, showAll]);

  return (
    <>
      {displayedApprovers.length === 0 ? (
        <span></span>
      ) : (
        <div
          ref={containerRef}
          className={`eps-approver-content-clip-wrapper ${showAll ? "wrap" : ""
            }`}
          style={{
            width: `${literals.cellWidth300}px`,
          }}
        >
          {displayedApprovers
            .slice(0, showAll ? displayedApprovers.length : visibleCount)
            .map((item: string, index: number) =>
              renderApproverItem(item, index, handleApproverRemove)
            )}
          {!showAll && visibleCount < displayedApprovers.length && (
            <Button
              onClick={handleShowAll}
              variant="link"
              className="more-link-button"
            >
              +{displayedApprovers.length - visibleCount}{" "}
              {displayedApprovers.length - visibleCount > 1 ? "others" : "other"}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
