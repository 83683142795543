import { colors } from "../../../theme/colors";

enum AllStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DELAYED = "DELAYED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  KEY_RISKS = "KEY_RISKS",
  KEY_ISSUES = "KEY_ISSUES",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export const BackgroundColorByStatus: any = {
  [AllStatus.PENDING]: colors.pendingBGColor,
  [AllStatus.COMPLETED]: colors.completdBGColor,
  [AllStatus.NOT_STARTED]: colors.notStartedBGColor,
  [AllStatus.CANCELLED]: colors.cancelledBGColor,
  [AllStatus.DELAYED]: colors.delayedBGColor,
  [AllStatus.IN_PROGRESS]: colors.pendingBGColor,
  [AllStatus.DONE]: colors.completdBGColor,
  [AllStatus.KEY_RISKS]: colors.keyRiskBGColor,
  [AllStatus.KEY_ISSUES]: colors.keyIssueBGColor,
  [AllStatus.LOW]: colors.lowIssueBGColor,
  [AllStatus.MEDIUM]: colors.mediumIssueBGColor,
  [AllStatus.HIGH]: colors.highIssueBGColor,
};
