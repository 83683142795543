import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";

export const ProjectDetailsQuery = createApi({
  reducerPath: "ProjectDetailsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getProjectDetails: builder.query({
      query: () => ({
        url: `/projects/group-by-status?statuses=COMPLETED&statuses=PENDING`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetProjectDetailsQuery } = ProjectDetailsQuery;
