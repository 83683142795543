import { DateConverter } from "../../ClientDetails/utils/DateConverter";
import { FileUploadItem, FormattedFileUploadItem } from "../CustomerDetails.type";

export const formatFileSize = (sizeInBytes: number): string => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
  };

  export  const getRelativeTime = (timestamp: string): string => {
    const now = new Date();
    const past = new Date(timestamp);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

    const units = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    for (const unit of units) {
      const elapsed = Math.floor(diffInSeconds / unit.seconds);
      if (elapsed > 0) {
        return elapsed === 1 ? `1 ${unit.label} ago` : `${elapsed} ${unit.label}s ago`;
      }
    }
    return "just now";
  };
  
  export const formatFileUploadData = (data: FileUploadItem[]): FormattedFileUploadItem[] => {
    return data.map(item => ({
      clientLogo: "", 
      clientName: item.tenantName, 
      accountManager: "", 
      onboardDate: "", 
      valueCaptured: "",
      fileUploaded: DateConverter(item.createdDate),
      tenantId: item.tenantId,
      status: item.status,
      fileName: item.fileName,
      id: item.id
    }));
  };
  

  
  