export function filterItemsBySearchString(
  availableItems: string[],
  searchString: string
) {
  if (searchString.trim())
    return availableItems.filter((element) => element.includes(searchString));
  else return availableItems;
}

export const getUniqueItems = (data: any, key: string): string[] => {
  const uniqueItems = new Set();
  data.forEach((item: any) => {
    if (Array.isArray(item[key])) {
      item[key].forEach((topic: string) => uniqueItems.add(topic));
    }
  });
  return Array.from(uniqueItems) as string[];
};

export const checkItemExistence = (existedItems: string[], newItem: string) => {
  return existedItems.includes(newItem);
};
