import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";

export const ProjectSummaryHomeQuery = createApi({
  reducerPath: "ProjectSummaryHomeQuery",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getProjectSummary: builder.query<any, void>({
      query: () => ({
        body: gql`
          query {
            execution {
              projectsSummary {
                totalCompletedProjects
                onGoingProjects {
                  id
                  tenantId
                  insightId
                  insightName
                  projectName
                  projectDescription
                  startDate
                  endDate
                  status
                  pctComplete
                  approvalStatus
                }
              }
              projectIssuesSummary {
                blockerKeyIssues {
                  id
                  tenantId
                  projectId
                  projectName
                  issueName
                  issueDescription
                  issueStatus
                  issueType
                  issuePriority
                }
                blockerKeyRisks {
                  id
                  tenantId
                  projectId
                  projectName
                  issueName
                  issueDescription
                  issueStatus
                  issueType
                  issuePriority
                }
              }
            }
          }
        `,
      }),
    }),
  }),
});

export const { useGetProjectSummaryQuery } = ProjectSummaryHomeQuery;
