import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";
import { capitalizeFirstLetter } from "./utils/ImpactAnalysisTableGrid";

export const Detractor = createApi({
  reducerPath: "Detractor",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getDetractor: builder.query<any, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        body: gql`
       {
  detractorCommonWords(startDate: "${startDate}", endDate:"${endDate}") {
    name
    frequency
  }
  detractorTopics(startDate: "${startDate}", endDate:"${endDate}") {
    name
    frequency
  }
}        
        `,
        variables: { startDate, endDate },
      }),
      transformResponse: (response: any) => {
        return {
          detractorCommonWords: response?.detractorCommonWords.map(
            (item: any) => ({
              ...item,
              name: item?.name ? capitalizeFirstLetter(item.name) : item.name,
            })
          ),
          detractorTopics: response?.detractorTopics.map((item: any) => ({
            ...item,
            name: item?.name ? capitalizeFirstLetter(item.name) : item.name,
          })),
        };
      },
    }),
  }),
});

export const { useGetDetractorQuery } = Detractor;
