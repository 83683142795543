export const literals = {
  signals: "SIGNALS",
  viewAll: "View All",
  nps: "NPS",
  csat: "CSAT",
  totalCustomers: "Total Customers",
  viewSummary: "View Summary",
  topThreeLoyal: "Top 3 Loyal Customers",
  topThreeAtRisk: "Top 3 At-Risk",
  chi: "CHI",
  netBenefits: "NET BENEFITS TO DATE",
  valueCaptured: "Value Captured",
  roi: "ROI",
  insights: "INSIGHTS",
  execution: "EXECUTION",
  customerSummary: "Customers Summary",
  npsTrend: "NPS Trend",
  npsAndCsatTrend: "NPS and CSAT Trend",
  surveysPlanned: "Surveys Planned",
  surveys: "Surveys",
  lastQuarterSurvey: "Last Quarter Surveys",
  overallCsatTrend: "Overall CSAT Trend",
  csatTouchPoints: "CSAT Touchpoints",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot password?",
  submit: "Submit",
  newUser: "New user? ",
  clickHere: "Click here",
  toCreateAccount: " to create an account.",
  poweredBy: "powered by ",
  upload: "Upload",
  clientName: "CLIENT NAME",
  accountManager: "ACCOUNT MANAGER",
  onboardDate: "ONBOARD DATE",
  valCaptured: "VALUE CAPTURED",
  fileUploaded: "FILE UPLOADED",
  fieldOne: "clientName",
  fieldTwo: "accountManager",
  fieldThree: "onboardDate",
  fieldFour: "valueCaptured",
  fieldFive: "fileUploaded",
  fieldSix: "upload",
  uploadTypeError: "Invalid file type. Please upload an Excel file.",
  uploadHeader: "Upload and attach files",
  supportedFormats: "Supported formats: xls, xlsx",
  clickToUpload: "Click to upload or drag and drop.",
  maximumFile: "Maximum file size 50 MB.",
  failed: "FAILED",
  fileName: "FILE NAME",
  status: "STATUS",
  fieldSeven: "fileName",
  fieldEight: "status",
  errors: "Errors",
  field: "Field",
  rejectedValue: "Rejected Value",
  message: "Message",
  returned: "Returned",
  notReturned: "Not Returned",
  up: "Up",
  down: "Down",
  responseRate: "Response Rate",
  sinceLastMonth: "since last month",
  totalSurveys: "Total Surveys",
  loyal: "loyal",
  neutral: "neutral",
  uploadDate: "UPLOAD DATE",
  userManagement: "User Management",
  about: "About",
  ClientDetails: "Client Details",
  uploadFile: "UPLOAD FILE",
  customerEffortScore: "Customer Effort Score",
  logout: "Logout",
  privacyPloicy: "Privacy Policy",
  termsAndConditions: "Terms & conditions",
  tenantLiterals: {
    tenantId: "ID",
    tenantName: "NAME",
    address: "ADDRESS",
    city: "CITY",
    stateProvince: "STATE PROVINCE",
    postalCode: "POSTAL CODE",
    industry: "INDUSTRY",
    tenantIdField: "id",
    tenantNameField: "name",
    addressField: "address",
    cityField: "city",
    stateProvinceField: "stateProvince",
    postalCodeField: "postalCode",
    industryField: "industry",
    phone: "PHONE",
    url: "URL",
    revenue: "REVENUE",
    phoneField: "phone",
    urlField: "url",
    revenueField: "revenue",
  },
  userLiterals: {
    username: "USERNAME",
    password: "PASSWORD",
    role: "ROLE",
    firstName: "FIRST NAME",
    lastName: "LAST NAME",
    usernameField: "username",
    passwordField: "password",
    roleField: "role",
    firstNameField: "firstName",
    lastNameField: "lastName",
    userIdField: "id",
    userId: "USER ID",
  },
  newlyUncovered: "Newly Uncovered",
  readyForAction: "Ready for action",
  totalInsights: "Total Insights",
  promotedInsights: "Promoted Insights",
  detailsOfPromotedInsights: "Details of promoted insights",
  detractorSaying: "What are our detractors saying",
  detractorTopics: "Detractor Topics",
  detractorCommonWords: "Detractor Common Words",
  insightImpactAnalysis: "Insight Impact Analysis",
  promoted: "Promoted",
  rankings: "Rankings",
  ranking: "Ranking",
  projectScoring: "Project Scoring",
  projectEstimation: "Project Estimation",
  unlockNewFeatures: "Unlock New Features",
  comingSoon: "Coming Soon",
  contactSales: "Contact Sales",
  noDataAdded: "No Data Added",
  contentCsat: "CONTENT CSAT",
  interceptResponseRates: "Intercept Response Rates",
  contentEffectiveness: "CONTENT EFFECTIVENESS",
  segmentName: "Segment Name",
  addCustomerToSegment: "Add customer to a segment",
  newSegmentCreated: "New segment created",
  customerSegmentUpdated: "Customer Segment Updated",
  somethingWentWrong: "Something went wrong !",
  dataAddSuccess: "Data added successfully",
  dataDeleteSuccess: "Data deleted successfully",
  dataUpdateSuccess: "Data updated successfully",
  topicAlreadyExist: "Topic already exist",
  tagAlreadyExist: "Tag already exist",
  assigneeAlreadyExist: "Assignee already exist",
  approverAlreadyExist: "Approver already exist",
  contributorAlreadyExist: "Contributor already exist",
  informedAlreadyExist: "Informed already exist",
  X: "X",
  search: "Search",
  existingTag: "Existing Tags",
  existingAssignee: "Existing Assignees",
  existingApprover: "Existing Approvers",
  existingContributors: "Existing Contributors",
  existingInformed: "Existing Informed",
  detractorTopicCellWidth: 300,
  detractorTopicDropdownHeight: 300,
  dropdownHeight300: 300,
  cellWidth300: 300,
  yearCellWidth: 300,
  netBenefitsCellWidth: 300,
  detractorCellWidth: 300,
  customDropdownHeight: 250,
  executionTimelinePopup: 100,
  eachOptionHeight: 29,
  multiSelectCellWidth: 250,
  pending: "Pending",
  completed: "Completed",
  delayed: "Delayed",
  cancelled: "Cancelled",
  inProgress: "In Progress",
  complete: "Complete",
  addNew: "Add New",
  tooltipCustomMetric: "Select checkbox to view the metric on dashboard.",
  projectsCompletedToDate: "Projects Completed To Date",
  blockerToValueCapture: "Blocker To Value Capture",
  project: "project",
  keyIssues: "Key Issues",
  keyRisks: "Key Risks",
  overallROI: "Overall ROI",
  overallROIDescription: "Overall ROI from all the projects completed",
  addCustomMetric: "Add custom Metric",
  customMetricAddFirst:
    "Add Custom Metric first from the ADD NEW dropdown and then Add Row for selected Custom Metric",
  specialCharRegex: /[!@#$%^&*(),.?":{}|<>]/,
  userPasswordMinLength: 8,
  userPasswordMinLengthError: (length: number) =>
    `Password must be at least ${length} characters long.`,
  userPasswordSpecialCharError:
    "Password must include at least one special character.",
  owner: "Owner",
  lastUpdated: "Last Updated",
  driver: "Driver",
  approver: "Approver",
  contributors: "Contributors",
  informed: "Informed",
  objective: "Objective",
  dueDate: "Due Date",
  noTagsAvailable: "No tags available",
  noAssigneesAvailable: "No assignees available",
  noApproversAvailable: "No approvers available",
  noContributorsAvailable: "No contributors available",
  noInformedAvailable: "No informed available",
};
