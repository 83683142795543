import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Signals } from "./page/Signals/SignalsQuery";
import { Admin } from "./page/Admin/AdminQuery";
import authReducer from "./page/Login/authSlice";
import authApi from "./page/Login/authApiSlice";
import headerApi from "./feature/Header/HeaderQuery";
import configReducer from "./feature/Header/ConfigSlice";
import layoutReducer from "./feature/Layout/LayoutSlice";
import { Tenant } from "./page/Tenant/TenantQuery";
import { Users } from "./page/Users/UsersQuery";
import { TenantCustomers } from "./page/ClientDetails/slice/TenantsCustomerQuery";
import { TenantNpsQuery } from "./page/ClientDetails/slice/TenantNpsQuery";
import { TenantCsatQuery } from "./page/ClientDetails/slice/TenantCsatQuery";
import { CHIQuery } from "./page/ClientDetails/slice/CHIQuery";
import { InsightsQuery } from "./page/ClientDetails/slice/InsightsQuery";
import { InsightsDetractorQuery } from "./page/ClientDetails/slice/InsightsDetractorQuery";
import { NetBenefitsQuery } from "./page/ClientDetails/slice/NetBenefitsQuery";
import { CustomMetricsQuery } from "./page/ClientDetails/slice/CustomMetricsQuery";
import { InsightHomeQuery } from "./page/Home/InsightHomeQuery";
import { NetBenefitsHomeQuery } from "./page/Home/NetBenefitsHomeQuery";
import { SurveyCrudQuery } from "./page/ClientDetails/slice/SurveyCrudQuery";
import { CustomerSegmentCrudQuery } from "./page/ClientDetails/slice/CustomerSegmentCrudQuery";
import { SignalsCommonCrudQuery } from "./page/ClientDetails/slice/SignalsCommonCrudQuery";
import { SignalsRestQuery } from "./page/Signals/SignalsRestQuery";
import { ContentEffectivenessCrudQuery } from "./page/ClientDetails/slice/ContentEffectivenessCrudQuery";
import { CustomerSummaryQuery } from "./page/CustomerSummary/CustomerSummaryQuery";
import customerReducer from "./page/ClientDetails/slice/CustomerSlice";
import projectReducer from "./page/ClientDetails/slice/ProjectSlice";
import customMetricsReducer from "./page/ClientDetails/slice/CustomMetricsSlice";
import InsightQueryReducer from "./page/ClientDetails/slice/InsightQuerySlice";
import ExecutionProjectIssueReducer from "./page/ClientDetails/slice/ExecutionProjectIssueQuerySlice";
import ExecutionProjectSummaryReducer from "./page/ClientDetails/slice/ExecutionProjectSummaryQuerySlice";
import InsightDetractorQueryReducer from "./page/ClientDetails/slice/InsightsDetractorQuerySlice";
import { ProjectInsightsQuery } from "./page/Ranking/ProjectInsightsQuery";
import { InsightAnalysisQuery } from "./page/InsightAnalysis/InsightAnalysisQuery";
import { ProjectDetailsQuery } from "./page/ExecutionDetails/ProjectDetailsQuery";
import { Detractor } from "./page/InsightAnalysis/DetractorQuery";
import { ExecutionProjectQuery } from "./page/ClientDetails/slice/ExecutionProjectQuery";
import { ExecutionProjectIssueQuery } from "./page/ClientDetails/slice/ExecutionProjectIssueQuery";
import { ExecutionProjectSummaryQuery } from "./page/ClientDetails/slice/ExecutionProjectSummaryQuery";
import AgGridQueryReducer from "./page/ClientDetails/slice/AgGridCustomSlice";
import { ProjectSummaryHomeQuery } from "./page/Home/ProjectSummaryHomeQuery";
import { EPITenantQuery } from "./page/ExecutionProjectIssues/EPITenantQuery";

const rootReducer = combineReducers({
  [Signals.reducerPath]: Signals.reducer,
  [Detractor.reducerPath]: Detractor.reducer,
  [Admin.reducerPath]: Admin.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [headerApi.reducerPath]: headerApi.reducer,
  [Tenant.reducerPath]: Tenant.reducer,
  [Users.reducerPath]: Users.reducer,
  [TenantCustomers.reducerPath]: TenantCustomers.reducer,
  [TenantNpsQuery.reducerPath]: TenantNpsQuery.reducer,
  [TenantCsatQuery.reducerPath]: TenantCsatQuery.reducer,
  [CHIQuery.reducerPath]: CHIQuery.reducer,
  [InsightsQuery.reducerPath]: InsightsQuery.reducer,
  [InsightsDetractorQuery.reducerPath]: InsightsDetractorQuery.reducer,
  [NetBenefitsQuery.reducerPath]: NetBenefitsQuery.reducer,
  [CustomMetricsQuery.reducerPath]: CustomMetricsQuery.reducer,
  [InsightHomeQuery.reducerPath]: InsightHomeQuery.reducer,
  [NetBenefitsHomeQuery.reducerPath]: NetBenefitsHomeQuery.reducer,
  [SurveyCrudQuery.reducerPath]: SurveyCrudQuery.reducer,
  [CustomerSegmentCrudQuery.reducerPath]: CustomerSegmentCrudQuery.reducer,
  [SignalsCommonCrudQuery.reducerPath]: SignalsCommonCrudQuery.reducer,
  [SignalsRestQuery.reducerPath]: SignalsRestQuery.reducer,
  [ContentEffectivenessCrudQuery.reducerPath]:
    ContentEffectivenessCrudQuery.reducer,
  [CustomerSummaryQuery.reducerPath]: CustomerSummaryQuery.reducer,
  [ProjectInsightsQuery.reducerPath]: ProjectInsightsQuery.reducer,
  [EPITenantQuery.reducerPath]: EPITenantQuery.reducer,
  [InsightAnalysisQuery.reducerPath]: InsightAnalysisQuery.reducer,
  [ProjectDetailsQuery.reducerPath]: ProjectDetailsQuery.reducer,
  [ExecutionProjectQuery.reducerPath]: ExecutionProjectQuery.reducer,
  [ExecutionProjectIssueQuery.reducerPath]: ExecutionProjectIssueQuery.reducer,
  [ExecutionProjectSummaryQuery.reducerPath]: ExecutionProjectSummaryQuery.reducer,
  [ProjectSummaryHomeQuery.reducerPath]: ProjectSummaryHomeQuery.reducer,
  auth: authReducer,
  config: configReducer,
  layout: layoutReducer,
  customer: customerReducer,
  project: projectReducer,
  customMetrics: customMetricsReducer,
  insight: InsightQueryReducer,
  executionProjectIssue: ExecutionProjectIssueReducer,
  executionProjectSummary: ExecutionProjectSummaryReducer,
  insightsDetractor: InsightDetractorQueryReducer,
  agGrid: AgGridQueryReducer,
});

const apiMiddlewares = [
  Signals.middleware,
  Detractor.middleware,
  Admin.middleware,
  authApi.middleware,
  headerApi.middleware,
  Tenant.middleware,
  Users.middleware,
  TenantCustomers.middleware,
  TenantNpsQuery.middleware,
  TenantCsatQuery.middleware,
  CHIQuery.middleware,
  InsightsQuery.middleware,
  InsightsDetractorQuery.middleware,
  NetBenefitsQuery.middleware,
  CustomMetricsQuery.middleware,
  InsightHomeQuery.middleware,
  NetBenefitsHomeQuery.middleware,
  SurveyCrudQuery.middleware,
  CustomerSegmentCrudQuery.middleware,
  SignalsCommonCrudQuery.middleware,
  SignalsRestQuery.middleware,
  ContentEffectivenessCrudQuery.middleware,
  CustomerSummaryQuery.middleware,
  ProjectInsightsQuery.middleware,
  EPITenantQuery.middleware,
  InsightAnalysisQuery.middleware,
  ProjectDetailsQuery.middleware,
  ExecutionProjectQuery.middleware,
  ExecutionProjectIssueQuery.middleware,
  ExecutionProjectSummaryQuery.middleware,
  ProjectSummaryHomeQuery.middleware,
];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...apiMiddlewares),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
