import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CustomMetricData {
  data: any[];
}

const initialState: CustomMetricData = { data: [] };

const customMetricsSlice = createSlice({
  name: "customMetric",
  initialState,
  reducers: {
    setCustomMetricsData: (state, action: PayloadAction<any[] | null>) => {
      state.data = action.payload ?? [];
    },
    clearCustomMetricsData: (state) => {
      state.data = [];
    },
  },
});

export const { setCustomMetricsData, clearCustomMetricsData } = customMetricsSlice.actions;

export default customMetricsSlice.reducer;