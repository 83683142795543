import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { literals } from "../../theme/literals";
import DropdownImg from "../../assets/images/FilledDropDown.svg";
import "../../sass/typography.scss";
import "../../styles/component/CustomSelectCellEditor/CustomSelectCellEditor.scss";

const CustomSelectCellEditor = (props: any) => {
  const agGridData = useSelector((state: RootState) => state.agGrid.data);
  const isFullRowEdit = agGridData.editType === "fullRow";
  // Initialize with current cell value
  const keyToShowValue = props?.keyToShowValue;
  const [value, setValue] = useState(props.value || "");
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  // using below state to resolve flickering issue of dropdown
  const [dropdownReady, setDropdownReady] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null); // Ref for the dropdown trigger (the div that is clicked to open the dropdown)

  const options = props.values || [];
  // dropdown height according to option length available
  const calcHeight = (options?.length || 1) * literals.eachOptionHeight;
  const handleChange = (selectedOption: string) => {
    setValue(selectedOption);
    setIsOpen(false);
    props.onValueChange(selectedOption);
    if (!isFullRowEdit) {
      // only call BE if not editing full row, As for row "Enter" event will be listened
      props.stopEditing();
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const calculateDropdownPosition = () => {
    if (!triggerRef.current) return { top: 0, left: 0, width: 0 };

    const rect = triggerRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom; // Space below the trigger
    const spaceAbove = rect.top; // Space above the trigger
    // find minimum height to a dropdown can obtain
    const minHeight = Math.min(literals.customDropdownHeight, calcHeight);
    let topPosition = rect.bottom;
    if (spaceBelow < minHeight) {
      // If there isn't enough space below, open the dropdown above
      topPosition = spaceAbove - minHeight + 15; // Adjust height as needed
    }

    return {
      top: topPosition,
      left: rect.left,
      width: rect.width,
    };
  };
  const handleScroll = () => {
    setIsOpen(false); // Close the dropdown on scroll
  };
  useEffect(() => {
    const verticalScrollContainer = document.querySelector(".ag-body-viewport"); // Vertical scroll container
    const horizontalScrollContainer = document.querySelector(
      ".ag-center-cols-viewport"
    ); // Horizontal scroll container

    const attachListeners = () => {
      verticalScrollContainer?.addEventListener("scroll", handleScroll);
      horizontalScrollContainer?.addEventListener("scroll", handleScroll);
    };

    const detachListeners = () => {
      verticalScrollContainer?.removeEventListener("scroll", handleScroll);
      horizontalScrollContainer?.removeEventListener("scroll", handleScroll);
    };

    if (isOpen) {
      setDropdownReady(true); // Enable rendering when the dropdown is open
      attachListeners();
    } else {
      setDropdownReady(false); // Disable rendering when the dropdown is closed
      detachListeners();
    }

    return () => {
      detachListeners(); // Clean up on component unmount or when dependencies change
    };
  }, [isOpen]);

  const handleEnterKey = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      props.stopEditing();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Attach keydown listener when editor is active
    document.addEventListener("keydown", handleEnterKey);
    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleToggleDropdown = () => {
    if (!isOpen) {
      // As dropdown is about to open, so let's first calculate height to give some
      // time to state change
      const position = calculateDropdownPosition();
      setDropdownPosition(position);
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <div ref={triggerRef} className="custom-select-cell-wrapper">
      <div
        onClick={handleToggleDropdown}
        className={`selected-value-display-wrapper ${props?.overrideSVDClass}`}
      >
        <span
          className="dropdown-value"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100% - 24px)", // Ensures space for the image
          }}
          title={value?.[keyToShowValue] || value}
        >
          {value?.[keyToShowValue] || value || "Select"}
        </span>
        <img className="dropdown-image" src={DropdownImg} alt="dropdown" />
      </div>
      {isOpen &&
        dropdownReady &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            className="custom-dropdown-wrapper"
            style={{
              top: `${dropdownPosition.top}px`, // Dynamic top position
              left: `${dropdownPosition.left}px`, // Dynamic left position
              width: `${dropdownPosition.width}px`,
              maxHeight: literals.customDropdownHeight - 20,
            }}
          >
            {options.map((option: any) => {
              const isActive =
                (value?.[keyToShowValue] || value) === (option?.name || option);
              return (
                <div
                  key={option?.name || option}
                  onClick={() => handleChange(option)}
                  className={`custom-option-wrapper ${props.overrideCOWClass}`}
                >
                  {props.component ? (
                    props.component({
                      option,
                      value: value?.[keyToShowValue] || value,
                      keyToShowValue,
                    })
                  ) : (
                    <div
                      className={`default-option-content caption2 ${
                        isActive ? "active" : ""
                      } ${props?.overrideDOCClass}`}
                    >
                      {option?.name || option}
                    </div>
                  )}
                </div>
              );
            })}
          </div>,
          document.body // Render dropdown outside of the grid container
        )}
    </div>
  );
};

export default CustomSelectCellEditor;
