import {
  FileStatusItem,
  FormattedFileStatusItem,
} from "../../Admin/CustomerDetails.type";
import { getRelativeTime } from "../../Admin/utils/formatFileSize";

export const DateConverter = (timestamp: string) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formattedFileStatusRowData = (
  data: FileStatusItem[]
): FormattedFileStatusItem[] => {
  return data?.map((item: FileStatusItem) => ({
    fileUploaded: getRelativeTime(item.createdDate),
    tenantId: item.tenantId,
    status: item.status,
    fileName: item.fileType,
    id: item.id,
    requestId: item.requestId,
    uploadDate: DateConverter(item.createdDate),
  }));
};

export const getMonthFromDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.getMonth() + 1;
};

export const getYearFromDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.getFullYear();
};

export const getDateInYearMonthDatFormat = (dateString?: string) => {
  let date = dateString ? new Date(dateString) : new Date();
  if (isNaN(date.getTime())) {
    date = new Date();
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getYearQuarterFromDate = (dateString?: any) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-indexed in JS

  const quarter = Math.ceil(month / 3); // Determine quarter (1-4)

  return { year, quarter: `Q${quarter}` };
}

export const getDateFromQuarterAndYear = (year: any, quarter: any) => {
  // Map each quarter to its starting month
  const quarterStartMonths: any = {
    Q1: '01', // January
    Q2: '04', // April
    Q3: '07', // July
    Q4: '10'  // October
  };
  const month = quarterStartMonths[quarter];
  return `${year}-${month}-01`;
}